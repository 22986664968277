<template>
  <div class="h-100">
    <div class="">
      <div class="d-flex flex-column flex-md-row justify-content-between align-items-center mt-3 mb-md-3 mt-md-0">
        <h3 class="mb-3 m-md-0">Recherche avancée</h3>
        <div class="d-flex flex-column flex-md-row justify-content-end align-items-center">
          <div @click="initFilters" class="mr-md-3 mb-3 mb-md-0 text-primary cursor-pointer">Effacer les filtres</div>
          <div v-if="!showFilters" @click="toggleFilters" class="text-primary cursor-pointer">Afficher les filtres</div>
          <div v-if="showFilters" @click="toggleFilters" class="text-primary cursor-pointer">Masquer les filtres</div>
          <!-- RÉSULTATS INDICATEURS -->
          <div class="d-flex ml-md-3 mt-3 mt-md-0" v-if="!loading">
            <div class="btn-group ml-auto" role="group">
              <download-excel
                :data="collaborateursPourExport"
                class="btn btn-primary cursor-pointer"
                name="collaborateurs.xls"
              >
                Exporter (.xls)
                <span class="badge badge-warning">{{ collaborateursFiltered.length }}</span>
              </download-excel>
              <div class="btn-group" role="group">
                <button
                  id="btnGroupDrop1"
                  type="button"
                  class="btn btn-primary dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                ></button>
                <div
                  class="dropdown-menu dropdown-menu-right"
                  aria-labelledby="btnGroupDrop1"
                  style="width: 330px"
                  v-background-3
                >
                  <a class="dropdown-item cursor-pointer" @click="emails">Copier les adresses mail</a>
                  <a class="dropdown-item cursor-pointer" @click="exportCsv">Exporter (.csv)</a>
                  <a class="dropdown-item cursor-pointer mb-3" @click="exportTempsPleins"
                    >Exporter le bilan équivalent temps plein</a
                  >
                  <Checkbox
                    id="3"
                    class="ml-4 cursor-pointer"
                    v-model="contratUnifies"
                    text="Exporter 1 ligne par contrat"
                    :iconActive="true"
                  ></Checkbox>
                  <Checkbox
                    id="4"
                    class="ml-4 cursor-pointer"
                    v-model="exportFormations"
                    text="Inclure les formations à l'export"
                    :iconActive="true"
                  ></Checkbox>
                  <Checkbox
                    id="5"
                    class="ml-4 mb-2 cursor-pointer"
                    v-model="exportHabilitations"
                    text="Inclure les habilitations à l'export"
                    :iconActive="true"
                  ></Checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class>
          <div class="d-flex flex-column flex-md-row">
            <filtre-select
              class="mr-md-4 mt-3 mt-md-0"
              inputText="Type de contrats"
              v-model.number="filtreContrat"
              :options="data.contratTypes"
              :disabled="false"
            ></filtre-select>
            <filtre-select
              class="mr-md-4 mt-3 mt-md-0"
              inputText="Société"
              v-model.number="filtreSociete"
              :options="data.societes"
              :disabled="false"
            ></filtre-select>
            <filtre-select
              class="mr-md-4 mt-3 mt-md-0"
              inputText="Etablissement"
              v-model.number="filtreEtablissement"
              :options="data.etablissements"
              :disabled="false"
            ></filtre-select>
            <BaseInputDatePicker
              v-background-3
              class="shadow-sm rounded p-2 mt-3 mt-md-0"
              v-model="filtreDate"
              inputText="Date"
              :iconActive="true"
            ></BaseInputDatePicker>
          </div>
          <div class="d-flex flex-column flex-md-row justify-content-between align-items-center">
            <filtre-alphabet v-model="filterLetter" class="w-100 d-none d-md-block"></filtre-alphabet>
            <div class="d-flex flex-column pt-3 pb-3" style="min-width: 270px">
              <Checkbox
                id="2"
                class
                v-model="filtreActif"
                text="Afficher uniquement les actifs"
                :iconActive="true"
              ></Checkbox>
            </div>
          </div>
          <div class="d-flex flex-column flex-md-row" v-if="showFilters">
            <filtre-select
              class="mr-md-4 mt-3"
              inputText="Catégorie"
              v-model.number="filtreCategorie"
              :options="data.expositionCategories"
              :disabled="false"
            ></filtre-select>
            <filtre-select
              class="mr-md-4 mt-3"
              inputText="Habilitation"
              v-model.number="filtreHabilitation"
              :options="data.habilitationLibelles"
              :disabled="false"
            ></filtre-select>
            <filtre-select
              class="mr-md-4 mt-3"
              inputText="Formation"
              v-model.number="filtreFormation"
              :options="data.formationLibelles"
              :disabled="false"
            ></filtre-select>
            <filtre-select
              class="mt-3"
              inputText="Compétences"
              v-model.number="filtreCompetence"
              :options="data.competenceLibelles"
              :disabled="false"
            ></filtre-select>
          </div>
          <div class="d-flex flex-column flex-md-row" v-if="showFilters">
            <filtre-select
              class="mr-md-4 mt-3"
              inputText="Accès"
              v-model.number="filtreAcces"
              :options="data.clientSites"
              :disabled="false"
            ></filtre-select>
            <filtre-select
              class="mr-md-4 mt-3"
              inputText="Bulletin par email"
              v-model.number="filtreBulletinMail"
              :options="[
                { id: 1, description: 'Oui' },
                { id: 0, description: 'Non' },
              ]"
              :disabled="false"
            ></filtre-select>
            <BaseInputDatePickerMonth
              v-background-3
              class="shadow-sm rounded p-2 pl-3 pr-3 mt-3 mr-md-4"
              v-model="filtreDateEntree"
              inputText="Entrée en"
              :iconActive="true"
              minimumView="month"
            ></BaseInputDatePickerMonth>
            <BaseInputDatePickerMonth
              v-background-3
              class="shadow-sm rounded p-2 pl-3 pr-3 mt-3"
              v-model="filtreDateSortie"
              inputText="Sortie en"
              :iconActive="true"
              minimumView="month"
            ></BaseInputDatePickerMonth>
          </div>
          <div class="d-flex flex-column flex-md-row" v-if="showFilters">
            <filtre-select
              class="mr-md-4 mt-3"
              inputText="tâches réalisées"
              v-model.number="filtreTask"
              :options="data.task"
              :disabled="false"
            ></filtre-select>
            <filtre-select
              class="mt-3"
              inputText="tâches non réalisées"
              v-model.number="filtreTaskReverse"
              :options="data.task"
              :disabled="false"
            ></filtre-select>
          </div>
          <div class="d-flex flex-column flex-md-row mb-3 mb-md-4" v-if="showFilters">
            <filtre-select
              class="mr-md-4 mt-3"
              inputText="Formation interne réalisée"
              v-model.number="filtreMooc"
              :options="data.formationInternes"
              :disabled="false"
            ></filtre-select>
            <filtre-select
              class="mt-3"
              inputText="Formation interne non réalisée"
              v-model.number="filtreMoocReverse"
              :options="data.formationInternes"
              :disabled="false"
            ></filtre-select>
          </div>
        </div>
      </div>
    </div>
    <!-- RESULTATS -->
    <div v-if="!loading" class="d-flex flex-column flex-md-row flex-md-wrap mt-2 mb-4">
      <div v-for="collaborateur in collaborateursFiltered" :key="collaborateur.id" class="width-item">
        <Item :collaborateur="collaborateur" @show="openCollab"></Item>
      </div>
    </div>
    <!-- LOADER -->
    <div class="d-flex align-items-center" v-if="loading">
      <loader class="m-5"></loader>
      <h2 class="m-0">Chargement...</h2>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import FiltreAlphabet from "@/components/collaborateurs/recherche/FiltreAlphabet.vue";
import FiltreSelect from "@/components/collaborateurs/recherche/FiltreSelect.vue";
import Item from "@/components/collaborateurs/recherche/Item.vue";
import Loader from "@/components/bases/Loader.vue";
import BaseInputDatePicker from "@/components/bases/InputDatePicker.vue";
import BaseInputDatePickerMonth from "@/components/bases/InputDatePickerMonth.vue";

import Checkbox from "@/components/bases/Checkbox.vue";
import { mapActions } from "vuex";

export default {
  components: {
    Loader,
    BaseInputDatePicker,
    BaseInputDatePickerMonth,
    Checkbox,
    Item,
    FiltreAlphabet,
    FiltreSelect,
  },
  data() {
    return {
      collaborateurs: {},
      loading: false,
      showFilters: false,
      filtreContrat: null,
      filtreSociete: null,
      filtreEtablissement: null,
      filtreActif: 1,
      filtreCategorie: null,
      filtreHabilitation: null,
      filtreFormation: null,
      filtreCompetence: null,
      filtreAcces: null,
      filterLetter: "",
      filtreDate: null,
      contratUnifies: false,
      exportFormations: false,
      exportHabilitations: false,
      filtreDateEntree: null,
      filtreDateSortie: null,
      filtreBulletinMail: null,
      filtreTask: null,
      filtreTaskReverse: null,
      filtreMooc: null,
      filtreMoocReverse: null,
    };
  },
  props: {},
  methods: {
    initFilters() {
      this.filtreContrat = null;
      this.filtreSociete = null;
      this.filtreEtablissement = null;
      this.filtreActif = null;
      this.filtreCategorie = null;
      this.filtreHabilitation = null;
      this.filtreFormation = null;
      this.filtreCompetence = null;
      this.filtreAcces = null;
      this.filtreDate = this.$moment().format("YYYY-MM-DD");
      this.contratUnifies = false;
      this.filtreDateEntree = null;
      this.filtreDateSortie = null;
      this.filtreBulletinMail = null;
    },
    toggleFilters() {
      this.showFilters = !this.showFilters;
    },
    openCollab(id) {
      let routeData = this.$router.resolve({
        name: "sirh_collaborateur_infos",
        params: { id: id },
      });
      window.open(routeData.href, "_blank");
    },
    exportCsv() {
      this.csvExport(this.collaborateursPourExport);
    },
    exportTempsPleins() {
      this.csvExport(this.collaborateursPourExportTempsPleins);
    },
    csvExport(arrData) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [Object.keys(arrData[0]).join(";"), ...arrData.map((item) => Object.values(item).join(";"))]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "export.csv");
      link.click();
    },
    deleteRetourChariot(str) {
      if (str) {
        return str.replace(/\n|\r|(\n\r)/g, " ");
      }
      return "";
    },
    descriptionById(list, id) {
      const index = this.data[list].findIndex((x) => x.id === id);
      if (this.data && this.data[list][index]) return this.data[list][index].description;
      else return "";
    },
    formations(collab) {
      let formations = [];
      if (
        collab.collaborateur_formation_all &&
        collab.collaborateur_formation_all.length > 0 &&
        this.exportFormations
      ) {
        collab.collaborateur_formation_all.forEach((frm) => {
          formations.push(
            this.descriptionById("formationLibelles", frm.formation_libelle_id) +
              " (" +
              this.$moment(frm.formation_date_validite).format("DD/MM/YYYY") +
              ") "
          );
        });

        return formations.join(", ");
      }
      return "";
    },
    habilitations(collab) {
      let habilits = [];
      if (
        collab.collaborateur_habilitation_all &&
        collab.collaborateur_habilitation_all.length > 0 &&
        this.exportHabilitations
      ) {
        collab.collaborateur_habilitation_all.forEach((habilit) => {
          habilits.push(
            this.descriptionById("habilitationLibelles", habilit.habilitation_libelle_id) +
              " (" +
              this.$moment(habilit.habilitation_date_validite).format("DD/MM/YYYY") +
              ") "
          );
        });

        return habilits.join(", ");
      }
      return "";
    },
    calcTempsPlein(date_debut, date_fin, mois) {
      const Mstart = this.$moment(mois).startOf("month");
      const Mend = this.$moment(mois).endOf("month");
      const Cstart = this.$moment(date_debut);
      let Cend = null;
      if (date_fin !== null) Cend = this.$moment(date_fin);

      if (Cstart > Mstart && Cstart < Mend && (Cend > Mend || Cend == null)) {
        let Mjours = Mend.format("DD");
        let Cjours = Cstart.format("DD");
        let rep = ((Mjours - Cjours) / Mjours).toFixed(2);
        return rep.replace(".", ",");
      }

      if (Cstart < Mstart && Cend > Mend) {
        return 1;
      }

      if (Cstart < Mstart && Cend == null) {
        return 1;
      }

      if (Cend && Cend > Mstart && Cend < Mend) {
        let Mjours2 = Mend.format("DD");
        let Cjours2 = Cend.format("DD");
        let rep = (Cjours2 / Mjours2).toFixed(2);
        return rep.replace(".", ",");
      }
    },
    emails() {
      let _mails = "";
      if (this.collaborateursFiltered && this.collaborateursFiltered.length > 0)
        _mails = this.collaborateursFiltered
          .map((o) => {
            if (o["collaborateur_email_pro"]) return o["collaborateur_email_pro"];
            if (o["collaborateur_email_perso"]) return o["collaborateur_email_perso"];
            return "";
          })
          .join(";");
      navigator.clipboard.writeText(_mails).then(function () {
        alert("les adresses mail sont copiées dans le presse papier");
      });
    },
    ...mapActions({
      getCollaborateursAll: "collaborateurs/getCollaborateursAll",
      getCollaborateurs: "collaborateurs/getCollaborateurs",
      getData: "collaborateurs/getData",
    }),
  },
  mounted() {
    this.filtreDate = this.$moment().format("YYYY-MM-DD");
    this.loading = true;
    this.getCollaborateursAll()
      .then((data) => (this.collaborateurs = data))
      .finally(() => (this.loading = false));
  },
  computed: {
    ...mapGetters({
      data: "collaborateurs/data",
      //collaborateurs: "collaborateurs/all"
    }),
    collaborateursFiltered: function () {
      let rep = this.collaborateurs;
      if (rep.length > 0) {
        if (this.filtreActif) {
          rep = rep.filter((collab) => collab.collaborateur_actif == this.filtreActif);
        }

        if (this.filtreContrat || this.filtreSociete || this.filtreEtablissement) {
          rep = rep.filter((collab) => {
            if (collab.collaborateur_contrats_all && collab.collaborateur_contrats_all.length >= 1) {
              let contratVersions = [];
              //let contratSelected = {}
              let versionSelected = {};
              let displayCollab = true;

              let __contrat = collab.collaborateur_contrats_all;
              let max_dtObj1 = this.$moment(__contrat[0].contrat_date_debut);
              let indexContratMaxDate = 0;

              // Identifier l'index max sur la date de début
              __contrat.forEach((dt, index) => {
                if (this.$moment(dt.contrat_date_debut) > max_dtObj1) {
                  max_dtObj1 = this.$moment(dt.contrat_date_debut);
                  indexContratMaxDate = index;
                }
              });

              // Get versions et le dernier contrat
              //if (__contrat[indexContratMaxDate].contrat_version_all) {
              contratVersions = __contrat[indexContratMaxDate].contrat_version_all;
              //contratSelected = __contrat[indexContratMaxDate];
              //}

              // Obtenir la dernière version
              // -----------------------------------------------------------------------------------
              // ANALYSE DES VERSIONS DU CONTRAT
              // -----------------------------------------------------------------------------------

              // Identifier la dernière version si plusieurs versions d'un contrat (sur date d'effet)
              if (contratVersions.length >= 1) {
                let max_dtObj = this.$moment(contratVersions[0].date_effet);
                let indexVersionMaxDate = 0;

                // Identifier l'index max sur la date d'effet
                contratVersions.forEach((dt, index) => {
                  if (this.$moment(dt.date_effet) > max_dtObj) {
                    max_dtObj = this.$moment(dt.date_effet);
                    indexVersionMaxDate = index;
                  }
                });

                // Dernière version basée sur la date d'effet
                versionSelected = contratVersions[indexVersionMaxDate];
              }

              if (this.filtreContrat && versionSelected.contrat_type_id !== this.filtreContrat) displayCollab = false;
              if (this.filtreSociete && versionSelected.contrat_siret.siret_societe_id !== this.filtreSociete)
                displayCollab = false;
              if (this.filtreEtablissement && versionSelected.contrat_siret.id !== this.filtreEtablissement)
                displayCollab = false;
              return displayCollab;
            }
          });
        }

        if (this.filtreBulletinMail) {
          rep = rep.filter((collab) => {
            if (collab.collaborateur_contrats_all) {
              return collab.collaborateur_contrats_all.some((contrat) =>
                contrat.contrat_version_all.some((version) => version.contrat_bulletin_mail == this.filtreBulletinMail)
              );
            }
          });
        }

        if (this.filtreCategorie) {
          rep = rep.filter((collab) => {
            if (collab.collaborateur_exposition_all) {
              return collab.collaborateur_categorie_id == this.filtreCategorie;
            }
          });
        }

        if (this.filtreFormation) {
          rep = rep.filter((collab) => {
            if (collab.collaborateur_formation_all) {
              return collab.collaborateur_formation_all.some(
                (frm) => frm.formation_libelle_id == this.filtreFormation && frm.formation_active === true
              );
            }
          });
        }

        if (this.filtreHabilitation) {
          rep = rep.filter((collab) => {
            if (collab.collaborateur_habilitation_all) {
              return collab.collaborateur_habilitation_all.some(
                (hab) => hab.habilitation_libelle_id == this.filtreHabilitation && hab.habilitation_active === true
              );
            }
          });
        }

        if (this.filtreCompetence) {
          rep = rep.filter((collab) => {
            if (collab.collaborateur_competence_all) {
              return collab.collaborateur_competence_all.some(
                (comp) => comp.competence_libelle_id == this.filtreCompetence
              );
            }
          });
        }

        if (this.filtreAcces) {
          rep = rep.filter((collab) => {
            if (collab.collaborateur_acces_all) {
              return collab.collaborateur_acces_all.some(
                (ac) => ac.acces_site_client_id == this.filtreAcces && ac.acces_active === true
              );
            }
          });
        }

        if (this.filterLetter) {
          rep = rep.filter((collab) => {
            if (collab.collaborateur_nom.charAt(0) === this.filterLetter) {
              return true;
            }
          });
        }

        if (this.filtreDateEntree) {
          rep = rep.filter((collab) => {
            if (collab.collaborateur_contrats_all) {
              return collab.collaborateur_contrats_all.some(
                (contrat) =>
                  this.$moment(contrat.contrat_date_debut).format("MMMM-YYYY") ===
                  this.$moment(this.filtreDateEntree).format("MMMM-YYYY")
              );
            }
          });
        }

        if (this.filtreDateSortie) {
          rep = rep.filter((collab) => {
            if (collab.collaborateur_contrats_all) {
              return collab.collaborateur_contrats_all.some(
                (contrat) =>
                  this.$moment(contrat.contrat_date_fin).format("MMMM-YYYY") ===
                  this.$moment(this.filtreDateSortie).format("MMMM-YYYY")
              );
            }
          });
        }

        if (this.filtreTask) {
          rep = rep.filter((collab) => {
            if (collab.collaborateur_tasks) {
              return collab.collaborateur_tasks.some((task) => task.libelle_id == this.filtreTask && task.na !== false);
            }
          });
        }

        if (this.filtreTaskReverse) {
          rep = rep.filter((collab) => {
            if (collab.collaborateur_tasks) {
              return !collab.collaborateur_tasks.some(
                (task) => task.libelle_id == this.filtreTaskReverse && task.na !== false
              );
            }
          });
        }

        if (this.filtreMooc) {
          rep = rep.filter((collab) => {
            if (collab.collaborateur_mooc) {
              return collab.collaborateur_mooc.some(
                (frm) => frm.formation_id == this.filtreMooc && frm.realisee == true
              );
            }
          });
        }

        if (this.filtreMoocReverse) {
          rep = rep.filter((collab) => {
            if (collab.collaborateur_mooc) {
              return !collab.collaborateur_mooc.some(
                (frm) => frm.formation_id == this.filtreMoocReverse && frm.realisee == true
              );
            }
          });
        }
      }
      return rep;
    },
    collaborateursPourExport: function () {
      let rep = [];
      let arrData = this.collaborateursFiltered;

      if (arrData.length > 0) {
        // Boucle sur les collaborateurs
        arrData.forEach((collab) => {
          // Initialisation
          let activeContrat = {};
          let activeVersion = {};

          //************************************************
          // SELECTION AUTO DES CONTRATS / VERSION
          //************************************************
          if (!this.contratUnifies) {
            // Tableau des versions de contrat
            let contratVersions = [];

            // Version sélectionné (la plus récente)
            let versionSelected = {};

            if (collab && collab.collaborateur_contrats_all && collab.collaborateur_contrats_all.length >= 1) {
              let __contrat = collab.collaborateur_contrats_all;
              let max_dtObj1 = this.$moment(__contrat[0].contrat_date_debut);
              let indexContratMaxDate = 0;

              // Identifier l'index max sur la date de début
              __contrat.forEach((dt, index) => {
                if (dt && dt.contrat_date_debut && this.$moment(dt.contrat_date_debut) > max_dtObj1) {
                  max_dtObj1 = this.$moment(dt.contrat_date_debut);
                  indexContratMaxDate = index;
                }
              });

              // Get versions et le dernier contrat
              if (__contrat[indexContratMaxDate].contrat_version_all) {
                contratVersions = __contrat[indexContratMaxDate].contrat_version_all;
              }

              // Obtenir la dernière version
              // -----------------------------------------------------------------------------------
              // ANALYSE DES VERSIONS DU CONTRAT
              // -----------------------------------------------------------------------------------

              // Identifier la dernière version si plusieurs versions d'un contrat (sur date d'effet)
              if (contratVersions.length >= 1) {
                let max_dtObj = this.$moment(contratVersions[0].date_effet);
                let indexVersionMaxDate = 0;

                // Identifier l'index max sur la date d'effet
                contratVersions.forEach((dt, index) => {
                  if (this.$moment(dt.date_effet) > max_dtObj) {
                    max_dtObj = this.$moment(dt.date_effet);
                    indexVersionMaxDate = index;
                  }
                });

                // Dernière version basée sur la date d'effet
                versionSelected = contratVersions[indexVersionMaxDate];
              }

              activeContrat = __contrat[indexContratMaxDate];
              activeVersion = versionSelected;
            } else {
              activeContrat = {};
              activeVersion = {};
            }
            //************************************************

            if (activeContrat) {
              if (activeVersion) {
                rep.push({
                  id: collab.id,
                  collaborateur_matricule: collab.collaborateur_matricule,
                  collaborateur_nom: collab.collaborateur_nom,
                  collaborateur_prenom: collab.collaborateur_prenom,
                  collaborateur_rinpp: "'" + collab.collaborateur_rinpp,
                  collaborateur_sexe_id: this.descriptionById("sexes", collab.collaborateur_sexe_id),
                  collaborateur_nationalite_id: this.descriptionById(
                    "nationalites",
                    collab.collaborateur_nationalite_id
                  ),
                  collaborateur_type_doc: collab.collaborateur_type_doc,
                  collaborateur_doc_validite: collab.collaborateur_doc_validite,

                  collaborateur_etranger_num_ordre: collab.collaborateur_etranger_num_ordre,
                  collaborateur_etranger_type_doc: collab.collaborateur_etranger_type_doc,
                  collaborateur_etranger_validite: collab.collaborateur_etranger_validite,

                  collaborateur_handicape: collab.collaborateur_handicape,
                  collaborateur_rqth_validite: collab.collaborateur_rqth_validite,

                  collaborateur_date_naissance: collab.collaborateur_date_naissance,
                  collaborateur_adresse_naissance: this.deleteRetourChariot(collab.collaborateur_adresse_naissance),
                  collaborateur_email_pro: collab.collaborateur_email_pro,
                  collaborateur_email_perso: collab.collaborateur_email_perso,
                  ollaborateur_tel_1: "'" + collab.collaborateur_tel_1,
                  collaborateur_tel_2: collab.collaborateur_tel_2,
                  collaborateur_tel_3: collab.collaborateur_tel_3,
                  collaborateur_tel_poste: collab.collaborateur_tel_poste,
                  collaborateur_adresse: this.deleteRetourChariot(collab.collaborateur_adresse),
                  collaborateur_carnet_acces: collab.collaborateur_carnet_acces,
                  collaborateur_carte_orange: collab.collaborateur_carte_orange,
                  collaborateur_badge_edf: collab.collaborateur_badge_edf,
                  collaborateur_badge_cea: collab.collaborateur_badge_cea,
                  collaborateur_badge_autre: collab.collaborateur_badge_autre,
                  collaborateur_situation_famille_id: this.descriptionById(
                    "situationsFamille",
                    collab.collaborateur_situation_famille_id
                  ),
                  collaborateur_enfants: collab.collaborateur_enfants,
                  collaborateur_niveau_formation_id: this.descriptionById(
                    "niveauxFormation",
                    collab.collaborateur_niveau_formation_id
                  ),
                  collaborateur_age: collab.collaborateur_age,
                  collaborateur_actif: collab.collaborateur_actif,
                  formations: this.formations(collab),
                  habilitations: this.habilitations(collab),
                  anciennete: collab.anciennete,
                  contrat_type: this.descriptionById("contratTypes", activeVersion.contrat_type_id),
                  contrat_date_debut: activeContrat.contrat_date_debut,
                  contrat_date_fin: activeContrat.contrat_date_fin,
                  version: activeVersion.version,
                  date_effet: activeVersion.date_effet,
                  siret: activeVersion.contrat_siret ? activeVersion.contrat_siret.siret : "",
                  societe: activeVersion.contrat_siret ? activeVersion.contrat_siret.societe : "",
                  etablissement: activeVersion.contrat_siret ? activeVersion.contrat_siret.etablissement : "",
                  contrat_statut_id: this.descriptionById("contratStatus", activeVersion.contrat_statut_id),
                  contrat_libelle: activeVersion.contrat_libelle,
                  //contrat_siret_id: activeVersion.contrat_siret_id,
                  contrat_id: activeVersion.contrat_id,
                  contrat_mention_spe: activeVersion.contrat_mention_spe,
                  contrat_temps_travail: activeVersion.contrat_temps_travail,
                  contrat_position: activeVersion.contrat_position,
                  contrat_coef: activeVersion.contrat_coef,
                  contrat_brut_annuel: activeVersion.contrat_brut_annuel,
                  contrat_base_mensuelle: activeVersion.contrat_base_mensuelle,
                  contrat_taux_horaire: activeVersion.contrat_taux_horaire,
                  contrat_code_comptable: activeVersion.contrat_code_comptable,
                  contrat_rem_variable: activeVersion.contrat_rem_variable,
                  contrat_rtt: activeVersion.contrat_rtt,
                  contrat_temps_partiel: activeVersion.contrat_temps_partiel,
                  contrat_virement: activeVersion.contrat_virement,
                  contrat_bulletin_mail: activeVersion.contrat_bulletin_mail,
                  version_commentaire: activeVersion.version_commentaire,
                  rupture_contrat: this.descriptionById("contratRuptures", activeContrat.contrat_rupture_id),
                  rupture_commentaire: activeContrat.contrat_rupture_commentaire,
                  acces_qwark_mdp: collab.collaborateur_have_password,
                  acces_qwark_manager: collab.qwark_manager,
                  acces_qwark_mdp_changed: collab.password_changed,
                });
              } else {
                rep.push({
                  id: collab.id,
                  collaborateur_matricule: collab.collaborateur_matricule,
                  collaborateur_nom: collab.collaborateur_nom,
                  collaborateur_prenom: collab.collaborateur_prenom,
                  collaborateur_rinpp: "'" + collab.collaborateur_rinpp,
                  collaborateur_sexe_id: this.descriptionById("sexes", collab.collaborateur_sexe_id),
                  collaborateur_nationalite_id: this.descriptionById(
                    "nationalites",
                    collab.collaborateur_nationalite_id
                  ),
                  collaborateur_type_doc: collab.collaborateur_type_doc,
                  collaborateur_doc_validite: collab.collaborateur_doc_validite,

                  collaborateur_etranger_num_ordre: collab.collaborateur_etranger_num_ordre,
                  collaborateur_etranger_type_doc: collab.collaborateur_etranger_type_doc,
                  collaborateur_etranger_validite: collab.collaborateur_etranger_validite,

                  collaborateur_handicape: collab.collaborateur_handicape,
                  collaborateur_rqth_validite: collab.collaborateur_rqth_validite,

                  collaborateur_date_naissance: collab.collaborateur_date_naissance,
                  collaborateur_adresse_naissance: this.deleteRetourChariot(collab.collaborateur_adresse_naissance),
                  collaborateur_email_pro: collab.collaborateur_email_pro,
                  collaborateur_email_perso: collab.collaborateur_email_perso,
                  ollaborateur_tel_1: "'" + collab.collaborateur_tel_1,
                  collaborateur_tel_2: collab.collaborateur_tel_2,
                  collaborateur_tel_3: collab.collaborateur_tel_3,
                  collaborateur_tel_poste: collab.collaborateur_tel_poste,
                  collaborateur_adresse: this.deleteRetourChariot(collab.collaborateur_adresse),
                  collaborateur_carnet_acces: collab.collaborateur_carnet_acces,
                  collaborateur_carte_orange: collab.collaborateur_carte_orange,
                  collaborateur_badge_edf: collab.collaborateur_badge_edf,
                  collaborateur_badge_cea: collab.collaborateur_badge_cea,
                  collaborateur_badge_autre: collab.collaborateur_badge_autre,
                  collaborateur_situation_famille_id: this.descriptionById(
                    "situationsFamille",
                    collab.collaborateur_situation_famille_id
                  ),
                  collaborateur_enfants: collab.collaborateur_enfants,
                  collaborateur_niveau_formation_id: this.descriptionById(
                    "niveauxFormation",
                    collab.collaborateur_niveau_formation_id
                  ),
                  collaborateur_age: collab.collaborateur_age,
                  collaborateur_actif: collab.collaborateur_actif,
                  formations: this.formations(collab),
                  habilitations: this.habilitations(collab),
                  contrat_date_debut: activeContrat.contrat_date_debut,
                  contrat_date_fin: activeContrat.contrat_date_fin,
                  version: "",
                  date_effet: "",
                  contrat_type: "",
                  siret: "",
                  societe: "",
                  etablissement: "",
                  contrat_statut_id: "",
                  contrat_libelle: "",
                  //contrat_siret_id: "",
                  contrat_id: "",
                  contrat_mention_spe: "",
                  contrat_temps_travail: "",
                  contrat_position: "",
                  contrat_coef: "",
                  contrat_brut_annuel: "",
                  contrat_base_mensuelle: "",
                  contrat_taux_horaire: "",
                  contrat_code_comptable: "",
                  contrat_rem_variable: "",
                  contrat_rtt: "",
                  contrat_temps_partiel: "",
                  contrat_virement: "",
                  contrat_bulletin_mail: "",
                  version_commentaire: "",
                  rupture_contrat: "",
                  rupture_commentaire: "",
                  acces_qwark_mdp: collab.collaborateur_have_password,
                  acces_qwark_manager: collab.qwark_manager,
                  acces_qwark_mdp_changed: collab.password_changed,
                });
              }
            } else {
              rep.push({
                id: collab.id,
                collaborateur_matricule: collab.collaborateur_matricule,
                collaborateur_nom: collab.collaborateur_nom,
                collaborateur_prenom: collab.collaborateur_prenom,
                collaborateur_rinpp: "'" + collab.collaborateur_rinpp,
                collaborateur_sexe_id: this.descriptionById("sexes", collab.collaborateur_sexe_id),
                collaborateur_nationalite_id: this.descriptionById("nationalites", collab.collaborateur_nationalite_id),
                collaborateur_type_doc: collab.collaborateur_type_doc,
                collaborateur_doc_validite: collab.collaborateur_doc_validite,
                collaborateur_etranger_num_ordre: collab.collaborateur_etranger_num_ordre,
                collaborateur_etranger_type_doc: collab.collaborateur_etranger_type_doc,
                collaborateur_etranger_validite: collab.collaborateur_etranger_validite,
                collaborateur_handicape: collab.collaborateur_handicape,
                collaborateur_rqth_validite: collab.collaborateur_rqth_validite,
                collaborateur_date_naissance: collab.collaborateur_date_naissance,
                collaborateur_adresse_naissance: this.deleteRetourChariot(collab.collaborateur_adresse_naissance),
                collaborateur_email_pro: collab.collaborateur_email_pro,
                collaborateur_email_perso: collab.collaborateur_email_perso,
                ollaborateur_tel_1: "'" + collab.collaborateur_tel_1,
                collaborateur_tel_2: collab.collaborateur_tel_2,
                collaborateur_tel_3: collab.collaborateur_tel_3,
                collaborateur_tel_poste: collab.collaborateur_tel_poste,
                collaborateur_adresse: this.deleteRetourChariot(collab.collaborateur_adresse),
                collaborateur_carnet_acces: collab.collaborateur_carnet_acces,
                collaborateur_carte_orange: collab.collaborateur_carte_orange,
                collaborateur_badge_edf: collab.collaborateur_badge_edf,
                collaborateur_badge_cea: collab.collaborateur_badge_cea,
                collaborateur_badge_autre: collab.collaborateur_badge_autre,
                collaborateur_situation_famille_id: this.descriptionById(
                  "situationsFamille",
                  collab.collaborateur_situation_famille_id
                ),
                collaborateur_enfants: collab.collaborateur_enfants,
                collaborateur_niveau_formation_id: this.descriptionById(
                  "niveauxFormation",
                  collab.collaborateur_niveau_formation_id
                ),
                collaborateur_age: collab.collaborateur_age,
                collaborateur_actif: collab.collaborateur_actif,
                formations: this.formations(collab),
                habilitations: this.habilitations(collab),
                anciennete: collab.anciennete,
                contrat_type_id: "",
                contrat_date_debut: "",
                contrat_date_fin: "",
                version: "",
                date_effet: "",
                siret: "",
                societe: "",
                etablissement: "",
                contrat_statut_id: "",
                contrat_libelle: "",
                //contrat_siret_id: "",
                contrat_id: "",
                contrat_mention_spe: "",
                contrat_temps_travail: "",
                contrat_position: "",
                contrat_coef: "",
                contrat_brut_annuel: "",
                contrat_base_mensuelle: "",
                contrat_taux_horaire: "",
                contrat_code_comptable: "",
                contrat_rem_variable: "",
                contrat_rtt: "",
                contrat_temps_partiel: "",
                contrat_virement: "",
                contrat_bulletin_mail: "",
                version_commentaire: "",
                rupture_contrat: "",
                rupture_commentaire: "",
                acces_qwark_mdp: collab.collaborateur_have_password,
                acces_qwark_manager: collab.qwark_manager,
                acces_qwark_mdp_changed: collab.password_changed,
              });
            }

            //*****************************************************************************
            // AFFICHER TOUS LES CONTRATS (REGISTRE DU PERSONNEL) UNE LIGNE PAR CONTRAT
            //*****************************************************************************
          } else {
            let contrats = [];
            let contratVersions = [];
            contrats = collab.collaborateur_contrats_all;

            contrats.forEach((contrat) => {
              if (contrat.contrat_version_all.length > 0) {
                activeContrat = contrat;
                contratVersions = contrat.contrat_version_all;

                // -----------------------------------------------------------------------------------
                // ANALYSE DES VERSIONS DU CONTRAT
                // -----------------------------------------------------------------------------------

                // Identifier la dernière version si plusieurs versions d'un contrat (sur date d'effet)
                if (contratVersions.length >= 1) {
                  let max_dtObj = this.$moment(contratVersions[0].date_effet);
                  let indexVersionMaxDate = 0;

                  // Identifier l'index max sur la date d'effet
                  contratVersions.forEach((dt, index) => {
                    if (this.$moment(dt.date_effet) > max_dtObj) {
                      max_dtObj = this.$moment(dt.date_effet);
                      indexVersionMaxDate = index;
                    }
                  });

                  // Dernière version basée sur la date d'effet
                  activeVersion = contratVersions[indexVersionMaxDate];
                } else {
                  activeVersion = {};
                }
              }
              if (activeVersion) {
                rep.push({
                  id: collab.id,
                  collaborateur_matricule: collab.collaborateur_matricule,
                  collaborateur_nom: collab.collaborateur_nom,
                  collaborateur_prenom: collab.collaborateur_prenom,
                  collaborateur_rinpp: "'" + collab.collaborateur_rinpp,
                  collaborateur_sexe_id: this.descriptionById("sexes", collab.collaborateur_sexe_id),
                  collaborateur_nationalite_id: this.descriptionById(
                    "nationalites",
                    collab.collaborateur_nationalite_id
                  ),
                  collaborateur_type_doc: collab.collaborateur_type_doc,
                  collaborateur_doc_validite: collab.collaborateur_doc_validite,
                  collaborateur_etranger_num_ordre: collab.collaborateur_etranger_num_ordre,
                  collaborateur_etranger_type_doc: collab.collaborateur_etranger_type_doc,
                  collaborateur_etranger_validite: collab.collaborateur_etranger_validite,
                  collaborateur_date_naissance: collab.collaborateur_date_naissance,
                  collaborateur_adresse_naissance: this.deleteRetourChariot(collab.collaborateur_adresse_naissance),
                  collaborateur_email_pro: collab.collaborateur_email_pro,
                  collaborateur_email_perso: collab.collaborateur_email_perso,
                  ollaborateur_tel_1: "'" + collab.collaborateur_tel_1,
                  collaborateur_tel_2: collab.collaborateur_tel_2,
                  collaborateur_tel_3: collab.collaborateur_tel_3,
                  collaborateur_tel_poste: collab.collaborateur_tel_poste,
                  collaborateur_adresse: this.deleteRetourChariot(collab.collaborateur_adresse),
                  collaborateur_carnet_acces: collab.collaborateur_carnet_acces,
                  collaborateur_carte_orange: collab.collaborateur_carte_orange,
                  collaborateur_badge_edf: collab.collaborateur_badge_edf,
                  collaborateur_badge_cea: collab.collaborateur_badge_cea,
                  collaborateur_badge_autre: collab.collaborateur_badge_autre,
                  collaborateur_situation_famille_id: this.descriptionById(
                    "situationsFamille",
                    collab.collaborateur_situation_famille_id
                  ),
                  collaborateur_enfants: collab.collaborateur_enfants,
                  collaborateur_niveau_formation_id: this.descriptionById(
                    "niveauxFormation",
                    collab.collaborateur_niveau_formation_id
                  ),
                  collaborateur_age: collab.collaborateur_age,
                  collaborateur_actif: collab.collaborateur_actif,
                  formations: this.formations(collab),
                  habilitations: this.habilitations(collab),
                  anciennete: collab.anciennete,
                  contrat_type: this.descriptionById("contratTypes", activeVersion.contrat_type_id),
                  contrat_date_debut: activeContrat.contrat_date_debut,
                  contrat_date_fin: activeContrat.contrat_date_fin,
                  version: activeVersion.version,
                  date_effet: activeVersion.date_effet,
                  siret: activeVersion.contrat_siret ? activeVersion.contrat_siret.siret : "",
                  societe: activeVersion.contrat_siret ? activeVersion.contrat_siret.societe : "",
                  etablissement: activeVersion.contrat_siret ? activeVersion.contrat_siret.etablissement : "",
                  contrat_statut_id: this.descriptionById("contratStatus", activeVersion.contrat_statut_id),
                  contrat_libelle: activeVersion.contrat_libelle,
                  //contrat_siret_id: activeVersion.contrat_siret_id,
                  contrat_id: activeVersion.contrat_id,
                  contrat_mention_spe: activeVersion.contrat_mention_spe,
                  contrat_temps_travail: activeVersion.contrat_temps_travail,
                  contrat_position: activeVersion.contrat_position,
                  contrat_coef: activeVersion.contrat_coef,
                  contrat_brut_annuel: activeVersion.contrat_brut_annuel,
                  contrat_base_mensuelle: activeVersion.contrat_base_mensuelle,
                  contrat_taux_horaire: activeVersion.contrat_taux_horaire,
                  contrat_code_comptable: activeVersion.contrat_code_comptable,
                  contrat_rem_variable: activeVersion.contrat_rem_variable,
                  contrat_rtt: activeVersion.contrat_rtt,
                  contrat_temps_partiel: activeVersion.contrat_temps_partiel,
                  contrat_virement: activeVersion.contrat_virement,
                  contrat_bulletin_mail: activeVersion.contrat_bulletin_mail,
                  version_commentaire: activeVersion.version_commentaire,
                  rupture_contrat: this.descriptionById("contratRuptures", activeContrat.contrat_rupture_id),
                  rupture_commentaire: activeContrat.contrat_rupture_commentaire,
                  acces_qwark_mdp: collab.collaborateur_have_password,
                  acces_qwark_manager: collab.qwark_manager,
                  acces_qwark_mdp_changed: collab.password_changed,
                });
              } else {
                rep.push({
                  id: collab.id,
                  collaborateur_matricule: collab.collaborateur_matricule,
                  collaborateur_nom: collab.collaborateur_nom,
                  collaborateur_prenom: collab.collaborateur_prenom,
                  collaborateur_rinpp: "'" + collab.collaborateur_rinpp,
                  collaborateur_sexe_id: this.descriptionById("sexes", collab.collaborateur_sexe_id),
                  collaborateur_nationalite_id: this.descriptionById(
                    "nationalites",
                    collab.collaborateur_nationalite_id
                  ),
                  collaborateur_type_doc: collab.collaborateur_type_doc,
                  collaborateur_doc_validite: collab.collaborateur_doc_validite,

                  collaborateur_etranger_num_ordre: collab.collaborateur_etranger_num_ordre,
                  collaborateur_etranger_type_doc: collab.collaborateur_etranger_type_doc,
                  collaborateur_etranger_validite: collab.collaborateur_etranger_validite,

                  collaborateur_date_naissance: collab.collaborateur_date_naissance,
                  collaborateur_adresse_naissance: this.deleteRetourChariot(collab.collaborateur_adresse_naissance),
                  collaborateur_email_pro: collab.collaborateur_email_pro,
                  collaborateur_email_perso: collab.collaborateur_email_perso,
                  ollaborateur_tel_1: "'" + collab.collaborateur_tel_1,
                  collaborateur_tel_2: collab.collaborateur_tel_2,
                  collaborateur_tel_3: collab.collaborateur_tel_3,
                  collaborateur_tel_poste: collab.collaborateur_tel_poste,
                  collaborateur_adresse: this.deleteRetourChariot(collab.collaborateur_adresse),
                  collaborateur_carnet_acces: collab.collaborateur_carnet_acces,
                  collaborateur_carte_orange: collab.collaborateur_carte_orange,
                  collaborateur_badge_edf: collab.collaborateur_badge_edf,
                  collaborateur_badge_cea: collab.collaborateur_badge_cea,
                  collaborateur_badge_autre: collab.collaborateur_badge_autre,
                  collaborateur_situation_famille_id: this.descriptionById(
                    "situationsFamille",
                    collab.collaborateur_situation_famille_id
                  ),
                  collaborateur_enfants: collab.collaborateur_enfants,
                  collaborateur_niveau_formation_id: this.descriptionById(
                    "niveauxFormation",
                    collab.collaborateur_niveau_formation_id
                  ),
                  collaborateur_age: collab.collaborateur_age,
                  collaborateur_actif: collab.collaborateur_actif,
                  formations: this.formations(collab),
                  habilitations: this.habilitations(collab),
                  anciennete: collab.anciennete,
                  contrat_date_debut: activeContrat.contrat_date_debut,
                  contrat_date_fin: activeContrat.contrat_date_fin,
                  version: "",
                  date_effet: "",
                  contrat_type: "",
                  siret: "",
                  societe: "",
                  etablissement: "",
                  contrat_statut_id: "",
                  contrat_libelle: "",
                  //contrat_siret_id: "",
                  contrat_id: "",
                  contrat_mention_spe: "",
                  contrat_temps_travail: "",
                  contrat_position: "",
                  contrat_coef: "",
                  contrat_brut_annuel: "",
                  contrat_base_mensuelle: "",
                  contrat_taux_horaire: "",
                  contrat_code_comptable: "",
                  contrat_rem_variable: "",
                  contrat_rtt: "",
                  contrat_temps_partiel: "",
                  contrat_virement: "",
                  contrat_bulletin_mail: "",
                  version_commentaire: "",
                  rupture_contrat: "",
                  rupture_commentaire: "",
                  acces_qwark_mdp: collab.collaborateur_have_password,
                  acces_qwark_manager: collab.qwark_manager,
                  acces_qwark_mdp_changed: collab.password_changed,
                });
              }
            });
          }
        });
      }
      return rep;
    },
    collaborateursPourExportTempsPleins: function () {
      let rep = [];
      let arrData = this.collaborateursFiltered;

      if (arrData.length > 0) {
        // Boucle sur les collaborateurs
        arrData.forEach((collab) => {
          // Initialisation
          let activeContrat = {};
          let activeVersion = {};

          //************************************************
          // SELECTION AUTO DES CONTRATS / VERSION
          //************************************************
          if (!this.contratUnifies) {
            // Tableau des versions de contrat
            let contratVersions = [];

            // Version sélectionné (la plus récente)
            let versionSelected = {};

            if (collab && collab.collaborateur_contrats_all && collab.collaborateur_contrats_all.length >= 1) {
              let __contrat = collab.collaborateur_contrats_all;
              let max_dtObj1 = this.$moment(__contrat[0].contrat_date_debut);
              let indexContratMaxDate = 0;

              // Identifier l'index max sur la date de début
              __contrat.forEach((dt, index) => {
                if (dt && dt.contrat_date_debut && this.$moment(dt.contrat_date_debut) > max_dtObj1) {
                  max_dtObj1 = this.$moment(dt.contrat_date_debut);
                  indexContratMaxDate = index;
                }
              });

              // Get versions et le dernier contrat
              if (__contrat[indexContratMaxDate].contrat_version_all) {
                contratVersions = __contrat[indexContratMaxDate].contrat_version_all;
              }

              // Obtenir la dernière version
              // -----------------------------------------------------------------------------------
              // ANALYSE DES VERSIONS DU CONTRAT
              // -----------------------------------------------------------------------------------

              // Identifier la dernière version si plusieurs versions d'un contrat (sur date d'effet)
              if (contratVersions.length >= 1) {
                let max_dtObj = this.$moment(contratVersions[0].date_effet);
                let indexVersionMaxDate = 0;

                // Identifier l'index max sur la date d'effet
                contratVersions.forEach((dt, index) => {
                  if (this.$moment(dt.date_effet) > max_dtObj) {
                    max_dtObj = this.$moment(dt.date_effet);
                    indexVersionMaxDate = index;
                  }
                });

                // Dernière version basée sur la date d'effet
                versionSelected = contratVersions[indexVersionMaxDate];
              }

              activeContrat = __contrat[indexContratMaxDate];
              activeVersion = versionSelected;
            } else {
              activeContrat = {};
              activeVersion = {};
            }
            //************************************************

            if (activeContrat) {
              if (activeVersion) {
                rep.push({
                  id: collab.id,
                  collaborateur_matricule: collab.collaborateur_matricule,
                  collaborateur_nom: collab.collaborateur_nom,
                  collaborateur_prenom: collab.collaborateur_prenom,
                  collaborateur_rinpp: "'" + collab.collaborateur_rinpp,
                  collaborateur_sexe_id: this.descriptionById("sexes", collab.collaborateur_sexe_id),
                  collaborateur_nationalite_id: this.descriptionById(
                    "nationalites",
                    collab.collaborateur_nationalite_id
                  ),
                  collaborateur_type_doc: collab.collaborateur_type_doc,
                  collaborateur_doc_validite: collab.collaborateur_doc_validite,
                  collaborateur_etranger_num_ordre: collab.collaborateur_etranger_num_ordre,
                  collaborateur_etranger_type_doc: collab.collaborateur_etranger_type_doc,
                  collaborateur_etranger_validite: collab.collaborateur_etranger_validite,
                  collaborateur_handicape: collab.collaborateur_handicape,
                  collaborateur_rqth_validite: collab.collaborateur_rqth_validite,
                  collaborateur_date_naissance: collab.collaborateur_date_naissance,
                  collaborateur_adresse_naissance: this.deleteRetourChariot(collab.collaborateur_adresse_naissance),
                  collaborateur_email_pro: collab.collaborateur_email_pro,
                  collaborateur_email_perso: collab.collaborateur_email_perso,
                  ollaborateur_tel_1: "'" + collab.collaborateur_tel_1,
                  collaborateur_tel_2: collab.collaborateur_tel_2,
                  collaborateur_tel_3: collab.collaborateur_tel_3,
                  collaborateur_tel_poste: collab.collaborateur_tel_poste,
                  collaborateur_adresse: this.deleteRetourChariot(collab.collaborateur_adresse),
                  collaborateur_carnet_acces: collab.collaborateur_carnet_acces,
                  collaborateur_carte_orange: collab.collaborateur_carte_orange,
                  collaborateur_badge_edf: collab.collaborateur_badge_edf,
                  collaborateur_badge_cea: collab.collaborateur_badge_cea,
                  collaborateur_badge_autre: collab.collaborateur_badge_autre,
                  collaborateur_situation_famille_id: this.descriptionById(
                    "situationsFamille",
                    collab.collaborateur_situation_famille_id
                  ),
                  collaborateur_enfants: collab.collaborateur_enfants,
                  collaborateur_niveau_formation_id: this.descriptionById(
                    "niveauxFormation",
                    collab.collaborateur_niveau_formation_id
                  ),
                  collaborateur_age: collab.collaborateur_age,
                  collaborateur_actif: collab.collaborateur_actif,
                  formations: this.formations(collab),
                  habilitations: this.habilitations(collab),
                  anciennete: collab.anciennete,
                  contrat_type: this.descriptionById("contratTypes", activeVersion.contrat_type_id),
                  contrat_date_debut: activeContrat.contrat_date_debut,
                  contrat_date_fin: activeContrat.contrat_date_fin,
                  siret: activeVersion.contrat_siret ? activeVersion.contrat_siret.siret : "",
                  societe: activeVersion.contrat_siret ? activeVersion.contrat_siret.societe : "",
                  etablissement: activeVersion.contrat_siret ? activeVersion.contrat_siret.etablissement : "",
                  contrat_statut_id: this.descriptionById("contratStatus", activeVersion.contrat_statut_id),
                  contrat_temps_partiel: activeVersion.contrat_temps_partiel,
                  [this.$moment().subtract(24, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(24, "months")
                  ),
                  [this.$moment().subtract(23, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(23, "months")
                  ),
                  [this.$moment().subtract(22, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(22, "months")
                  ),
                  [this.$moment().subtract(21, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(21, "months")
                  ),
                  [this.$moment().subtract(20, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(20, "months")
                  ),
                  [this.$moment().subtract(19, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(19, "months")
                  ),
                  [this.$moment().subtract(18, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(18, "months")
                  ),
                  [this.$moment().subtract(17, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(17, "months")
                  ),
                  [this.$moment().subtract(16, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(16, "months")
                  ),
                  [this.$moment().subtract(15, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(15, "months")
                  ),
                  [this.$moment().subtract(14, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(14, "months")
                  ),
                  [this.$moment().subtract(13, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(13, "months")
                  ),
                  [this.$moment().subtract(12, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(12, "months")
                  ),
                  [this.$moment().subtract(11, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(11, "months")
                  ),
                  [this.$moment().subtract(10, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(10, "months")
                  ),
                  [this.$moment().subtract(9, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(9, "months")
                  ),
                  [this.$moment().subtract(8, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(8, "months")
                  ),
                  [this.$moment().subtract(7, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(7, "months")
                  ),
                  [this.$moment().subtract(6, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(6, "months")
                  ),
                  [this.$moment().subtract(5, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(5, "months")
                  ),
                  [this.$moment().subtract(4, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(4, "months")
                  ),
                  [this.$moment().subtract(3, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(3, "months")
                  ),
                  [this.$moment().subtract(2, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(2, "months")
                  ),
                  [this.$moment().subtract(1, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(1, "months")
                  ),
                  [this.$moment().subtract(0, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(0, "months")
                  ),
                });
              } else {
                rep.push({
                  id: collab.id,
                  collaborateur_matricule: collab.collaborateur_matricule,
                  collaborateur_nom: collab.collaborateur_nom,
                  collaborateur_prenom: collab.collaborateur_prenom,
                  collaborateur_rinpp: "'" + collab.collaborateur_rinpp,
                  collaborateur_sexe_id: this.descriptionById("sexes", collab.collaborateur_sexe_id),
                  collaborateur_nationalite_id: this.descriptionById(
                    "nationalites",
                    collab.collaborateur_nationalite_id
                  ),
                  collaborateur_type_doc: collab.collaborateur_type_doc,
                  collaborateur_doc_validite: collab.collaborateur_doc_validite,

                  collaborateur_etranger_num_ordre: collab.collaborateur_etranger_num_ordre,
                  collaborateur_etranger_type_doc: collab.collaborateur_etranger_type_doc,
                  collaborateur_etranger_validite: collab.collaborateur_etranger_validite,

                  collaborateur_handicape: collab.collaborateur_handicape,
                  collaborateur_rqth_validite: collab.collaborateur_rqth_validite,

                  collaborateur_date_naissance: collab.collaborateur_date_naissance,
                  collaborateur_adresse_naissance: this.deleteRetourChariot(collab.collaborateur_adresse_naissance),
                  collaborateur_email_pro: collab.collaborateur_email_pro,
                  collaborateur_email_perso: collab.collaborateur_email_perso,
                  ollaborateur_tel_1: "'" + collab.collaborateur_tel_1,
                  collaborateur_tel_2: collab.collaborateur_tel_2,
                  collaborateur_tel_3: collab.collaborateur_tel_3,
                  collaborateur_tel_poste: collab.collaborateur_tel_poste,
                  collaborateur_adresse: this.deleteRetourChariot(collab.collaborateur_adresse),
                  collaborateur_carnet_acces: collab.collaborateur_carnet_acces,
                  collaborateur_carte_orange: collab.collaborateur_carte_orange,
                  collaborateur_badge_edf: collab.collaborateur_badge_edf,
                  collaborateur_badge_cea: collab.collaborateur_badge_cea,
                  collaborateur_badge_autre: collab.collaborateur_badge_autre,
                  collaborateur_situation_famille_id: this.descriptionById(
                    "situationsFamille",
                    collab.collaborateur_situation_famille_id
                  ),
                  collaborateur_enfants: collab.collaborateur_enfants,
                  collaborateur_niveau_formation_id: this.descriptionById(
                    "niveauxFormation",
                    collab.collaborateur_niveau_formation_id
                  ),
                  collaborateur_age: collab.collaborateur_age,
                  collaborateur_actif: collab.collaborateur_actif,
                  formations: this.formations(collab),
                  habilitations: this.habilitations(collab),
                  anciennete: collab.anciennete,
                  contrat_date_debut: activeContrat.contrat_date_debut,
                  contrat_date_fin: activeContrat.contrat_date_fin,
                  siret: activeVersion.contrat_siret ? activeVersion.contrat_siret.siret : "",
                  societe: activeVersion.contrat_siret ? activeVersion.contrat_siret.societe : "",
                  etablissement: activeVersion.contrat_siret ? activeVersion.contrat_siret.etablissement : "",
                  contrat_statut_id: this.descriptionById("contratStatus", activeVersion.contrat_statut_id),
                  contrat_temps_partiel: activeVersion.contrat_temps_partiel,
                  [this.$moment().subtract(24, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(24, "months")
                  ),
                  [this.$moment().subtract(23, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(23, "months")
                  ),
                  [this.$moment().subtract(22, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(22, "months")
                  ),
                  [this.$moment().subtract(21, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(21, "months")
                  ),
                  [this.$moment().subtract(20, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(20, "months")
                  ),
                  [this.$moment().subtract(19, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(19, "months")
                  ),
                  [this.$moment().subtract(18, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(18, "months")
                  ),
                  [this.$moment().subtract(17, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(17, "months")
                  ),
                  [this.$moment().subtract(16, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(16, "months")
                  ),
                  [this.$moment().subtract(15, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(15, "months")
                  ),
                  [this.$moment().subtract(14, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(14, "months")
                  ),
                  [this.$moment().subtract(13, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(13, "months")
                  ),
                  [this.$moment().subtract(12, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(12, "months")
                  ),
                  [this.$moment().subtract(11, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(11, "months")
                  ),
                  [this.$moment().subtract(10, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(10, "months")
                  ),
                  [this.$moment().subtract(9, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(9, "months")
                  ),
                  [this.$moment().subtract(8, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(8, "months")
                  ),
                  [this.$moment().subtract(7, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(7, "months")
                  ),
                  [this.$moment().subtract(6, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(6, "months")
                  ),
                  [this.$moment().subtract(5, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(5, "months")
                  ),
                  [this.$moment().subtract(4, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(4, "months")
                  ),
                  [this.$moment().subtract(3, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(3, "months")
                  ),
                  [this.$moment().subtract(2, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(2, "months")
                  ),
                  [this.$moment().subtract(1, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(1, "months")
                  ),
                  [this.$moment().subtract(0, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(0, "months")
                  ),
                });
              }
            } else {
              rep.push({
                id: collab.id,
                collaborateur_matricule: collab.collaborateur_matricule,
                collaborateur_nom: collab.collaborateur_nom,
                collaborateur_prenom: collab.collaborateur_prenom,
                collaborateur_rinpp: "'" + collab.collaborateur_rinpp,
                collaborateur_sexe_id: this.descriptionById("sexes", collab.collaborateur_sexe_id),
                collaborateur_nationalite_id: this.descriptionById("nationalites", collab.collaborateur_nationalite_id),
                collaborateur_type_doc: collab.collaborateur_type_doc,
                collaborateur_doc_validite: collab.collaborateur_doc_validite,
                collaborateur_etranger_num_ordre: collab.collaborateur_etranger_num_ordre,
                collaborateur_etranger_type_doc: collab.collaborateur_etranger_type_doc,
                collaborateur_etranger_validite: collab.collaborateur_etranger_validite,
                collaborateur_handicape: collab.collaborateur_handicape,
                collaborateur_rqth_validite: collab.collaborateur_rqth_validite,
                collaborateur_date_naissance: collab.collaborateur_date_naissance,
                collaborateur_adresse_naissance: this.deleteRetourChariot(collab.collaborateur_adresse_naissance),
                collaborateur_email_pro: collab.collaborateur_email_pro,
                collaborateur_email_perso: collab.collaborateur_email_perso,
                ollaborateur_tel_1: "'" + collab.collaborateur_tel_1,
                collaborateur_tel_2: collab.collaborateur_tel_2,
                collaborateur_tel_3: collab.collaborateur_tel_3,
                collaborateur_tel_poste: collab.collaborateur_tel_poste,
                collaborateur_adresse: this.deleteRetourChariot(collab.collaborateur_adresse),
                collaborateur_carnet_acces: collab.collaborateur_carnet_acces,
                collaborateur_carte_orange: collab.collaborateur_carte_orange,
                collaborateur_badge_edf: collab.collaborateur_badge_edf,
                collaborateur_badge_cea: collab.collaborateur_badge_cea,
                collaborateur_badge_autre: collab.collaborateur_badge_autre,
                collaborateur_situation_famille_id: this.descriptionById(
                  "situationsFamille",
                  collab.collaborateur_situation_famille_id
                ),
                collaborateur_enfants: collab.collaborateur_enfants,
                collaborateur_niveau_formation_id: this.descriptionById(
                  "niveauxFormation",
                  collab.collaborateur_niveau_formation_id
                ),
                collaborateur_age: collab.collaborateur_age,
                collaborateur_actif: collab.collaborateur_actif,
                formations: this.formations(collab),
                habilitations: this.habilitations(collab),
                anciennete: collab.anciennete,
                contrat_type_id: "",
                contrat_date_debut: "",
                contrat_date_fin: "",
              });
            }

            //*****************************************************************************
            // AFFICHER TOUS LES CONTRATS (REGISTRE DU PERSONNEL) UNE LIGNE PAR CONTRAT
            //*****************************************************************************
          } else {
            let contrats = [];
            let contratVersions = [];
            contrats = collab.collaborateur_contrats_all;

            contrats.forEach((contrat) => {
              if (contrat.contrat_version_all.length > 0) {
                activeContrat = contrat;
                contratVersions = contrat.contrat_version_all;

                // -----------------------------------------------------------------------------------
                // ANALYSE DES VERSIONS DU CONTRAT
                // -----------------------------------------------------------------------------------

                // Identifier la dernière version si plusieurs versions d'un contrat (sur date d'effet)
                if (contratVersions.length >= 1) {
                  let max_dtObj = this.$moment(contratVersions[0].date_effet);
                  let indexVersionMaxDate = 0;

                  // Identifier l'index max sur la date d'effet
                  contratVersions.forEach((dt, index) => {
                    if (this.$moment(dt.date_effet) > max_dtObj) {
                      max_dtObj = this.$moment(dt.date_effet);
                      indexVersionMaxDate = index;
                    }
                  });

                  // Dernière version basée sur la date d'effet
                  activeVersion = contratVersions[indexVersionMaxDate];
                } else {
                  activeVersion = {};
                }
              }
              if (activeVersion) {
                rep.push({
                  id: collab.id,
                  collaborateur_matricule: collab.collaborateur_matricule,
                  collaborateur_nom: collab.collaborateur_nom,
                  collaborateur_prenom: collab.collaborateur_prenom,
                  collaborateur_rinpp: "'" + collab.collaborateur_rinpp,
                  collaborateur_sexe_id: this.descriptionById("sexes", collab.collaborateur_sexe_id),
                  collaborateur_nationalite_id: this.descriptionById(
                    "nationalites",
                    collab.collaborateur_nationalite_id
                  ),
                  collaborateur_type_doc: collab.collaborateur_type_doc,
                  collaborateur_doc_validite: collab.collaborateur_doc_validite,
                  collaborateur_etranger_num_ordre: collab.collaborateur_etranger_num_ordre,
                  collaborateur_etranger_type_doc: collab.collaborateur_etranger_type_doc,
                  collaborateur_etranger_validite: collab.collaborateur_etranger_validite,
                  collaborateur_date_naissance: collab.collaborateur_date_naissance,
                  collaborateur_adresse_naissance: this.deleteRetourChariot(collab.collaborateur_adresse_naissance),
                  collaborateur_email_pro: collab.collaborateur_email_pro,
                  collaborateur_email_perso: collab.collaborateur_email_perso,
                  ollaborateur_tel_1: "'" + collab.collaborateur_tel_1,
                  collaborateur_tel_2: collab.collaborateur_tel_2,
                  collaborateur_tel_3: collab.collaborateur_tel_3,
                  collaborateur_tel_poste: collab.collaborateur_tel_poste,
                  collaborateur_adresse: this.deleteRetourChariot(collab.collaborateur_adresse),
                  collaborateur_carnet_acces: collab.collaborateur_carnet_acces,
                  collaborateur_carte_orange: collab.collaborateur_carte_orange,
                  collaborateur_badge_edf: collab.collaborateur_badge_edf,
                  collaborateur_badge_cea: collab.collaborateur_badge_cea,
                  collaborateur_badge_autre: collab.collaborateur_badge_autre,
                  collaborateur_situation_famille_id: this.descriptionById(
                    "situationsFamille",
                    collab.collaborateur_situation_famille_id
                  ),
                  collaborateur_enfants: collab.collaborateur_enfants,
                  collaborateur_niveau_formation_id: this.descriptionById(
                    "niveauxFormation",
                    collab.collaborateur_niveau_formation_id
                  ),
                  collaborateur_age: collab.collaborateur_age,
                  collaborateur_actif: collab.collaborateur_actif,
                  formations: this.formations(collab),
                  habilitations: this.habilitations(collab),
                  anciennete: collab.anciennete,
                  contrat_type: this.descriptionById("contratTypes", activeVersion.contrat_type_id),
                  contrat_date_debut: activeContrat.contrat_date_debut,
                  contrat_date_fin: activeContrat.contrat_date_fin,
                  siret: activeVersion.contrat_siret ? activeVersion.contrat_siret.siret : "",
                  societe: activeVersion.contrat_siret ? activeVersion.contrat_siret.societe : "",
                  etablissement: activeVersion.contrat_siret ? activeVersion.contrat_siret.etablissement : "",
                  contrat_statut_id: this.descriptionById("contratStatus", activeVersion.contrat_statut_id),
                  contrat_temps_partiel: activeVersion.contrat_temps_partiel,
                  [this.$moment().subtract(24, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(24, "months")
                  ),
                  [this.$moment().subtract(23, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(23, "months")
                  ),
                  [this.$moment().subtract(22, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(22, "months")
                  ),
                  [this.$moment().subtract(21, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(21, "months")
                  ),
                  [this.$moment().subtract(20, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(20, "months")
                  ),
                  [this.$moment().subtract(19, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(19, "months")
                  ),
                  [this.$moment().subtract(18, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(18, "months")
                  ),
                  [this.$moment().subtract(17, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(17, "months")
                  ),
                  [this.$moment().subtract(16, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(16, "months")
                  ),
                  [this.$moment().subtract(15, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(15, "months")
                  ),
                  [this.$moment().subtract(14, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(14, "months")
                  ),
                  [this.$moment().subtract(13, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(13, "months")
                  ),
                  [this.$moment().subtract(12, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(12, "months")
                  ),
                  [this.$moment().subtract(11, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(11, "months")
                  ),
                  [this.$moment().subtract(10, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(10, "months")
                  ),
                  [this.$moment().subtract(9, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(9, "months")
                  ),
                  [this.$moment().subtract(8, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(8, "months")
                  ),
                  [this.$moment().subtract(7, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(7, "months")
                  ),
                  [this.$moment().subtract(6, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(6, "months")
                  ),
                  [this.$moment().subtract(5, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(5, "months")
                  ),
                  [this.$moment().subtract(4, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(4, "months")
                  ),
                  [this.$moment().subtract(3, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(3, "months")
                  ),
                  [this.$moment().subtract(2, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(2, "months")
                  ),
                  [this.$moment().subtract(1, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(1, "months")
                  ),
                  [this.$moment().subtract(0, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(0, "months")
                  ),
                });
              } else {
                rep.push({
                  id: collab.id,
                  collaborateur_matricule: collab.collaborateur_matricule,
                  collaborateur_nom: collab.collaborateur_nom,
                  collaborateur_prenom: collab.collaborateur_prenom,
                  collaborateur_rinpp: "'" + collab.collaborateur_rinpp,
                  collaborateur_sexe_id: this.descriptionById("sexes", collab.collaborateur_sexe_id),
                  collaborateur_nationalite_id: this.descriptionById(
                    "nationalites",
                    collab.collaborateur_nationalite_id
                  ),
                  collaborateur_type_doc: collab.collaborateur_type_doc,
                  collaborateur_doc_validite: collab.collaborateur_doc_validite,
                  collaborateur_etranger_num_ordre: collab.collaborateur_etranger_num_ordre,
                  collaborateur_etranger_type_doc: collab.collaborateur_etranger_type_doc,
                  collaborateur_etranger_validite: collab.collaborateur_etranger_validite,
                  collaborateur_date_naissance: collab.collaborateur_date_naissance,
                  collaborateur_adresse_naissance: this.deleteRetourChariot(collab.collaborateur_adresse_naissance),
                  collaborateur_email_pro: collab.collaborateur_email_pro,
                  collaborateur_email_perso: collab.collaborateur_email_perso,
                  ollaborateur_tel_1: "'" + collab.collaborateur_tel_1,
                  collaborateur_tel_2: collab.collaborateur_tel_2,
                  collaborateur_tel_3: collab.collaborateur_tel_3,
                  collaborateur_tel_poste: collab.collaborateur_tel_poste,
                  collaborateur_adresse: this.deleteRetourChariot(collab.collaborateur_adresse),
                  collaborateur_carnet_acces: collab.collaborateur_carnet_acces,
                  collaborateur_carte_orange: collab.collaborateur_carte_orange,
                  collaborateur_badge_edf: collab.collaborateur_badge_edf,
                  collaborateur_badge_cea: collab.collaborateur_badge_cea,
                  collaborateur_badge_autre: collab.collaborateur_badge_autre,
                  collaborateur_situation_famille_id: this.descriptionById(
                    "situationsFamille",
                    collab.collaborateur_situation_famille_id
                  ),
                  collaborateur_enfants: collab.collaborateur_enfants,
                  collaborateur_niveau_formation_id: this.descriptionById(
                    "niveauxFormation",
                    collab.collaborateur_niveau_formation_id
                  ),
                  collaborateur_age: collab.collaborateur_age,
                  collaborateur_actif: collab.collaborateur_actif,
                  formations: this.formations(collab),
                  habilitations: this.habilitations(collab),
                  anciennete: collab.anciennete,
                  contrat_date_debut: activeContrat.contrat_date_debut,
                  contrat_date_fin: activeContrat.contrat_date_fin,
                  siret: "",
                  societe: "",
                  etablissement: "",
                  contrat_statut_id: "",
                  contrat_temps_partiel: "",
                  [this.$moment().subtract(24, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(24, "months")
                  ),
                  [this.$moment().subtract(23, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(23, "months")
                  ),
                  [this.$moment().subtract(22, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(22, "months")
                  ),
                  [this.$moment().subtract(21, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(21, "months")
                  ),
                  [this.$moment().subtract(20, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(20, "months")
                  ),
                  [this.$moment().subtract(19, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(19, "months")
                  ),
                  [this.$moment().subtract(18, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(18, "months")
                  ),
                  [this.$moment().subtract(17, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(17, "months")
                  ),
                  [this.$moment().subtract(16, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(16, "months")
                  ),
                  [this.$moment().subtract(15, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(15, "months")
                  ),
                  [this.$moment().subtract(14, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(14, "months")
                  ),
                  [this.$moment().subtract(13, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(13, "months")
                  ),
                  [this.$moment().subtract(12, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(12, "months")
                  ),
                  [this.$moment().subtract(11, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(11, "months")
                  ),
                  [this.$moment().subtract(10, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(10, "months")
                  ),
                  [this.$moment().subtract(9, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(9, "months")
                  ),
                  [this.$moment().subtract(8, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(8, "months")
                  ),
                  [this.$moment().subtract(7, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(7, "months")
                  ),
                  [this.$moment().subtract(6, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(6, "months")
                  ),
                  [this.$moment().subtract(5, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(5, "months")
                  ),
                  [this.$moment().subtract(4, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(4, "months")
                  ),
                  [this.$moment().subtract(3, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(3, "months")
                  ),
                  [this.$moment().subtract(2, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(2, "months")
                  ),
                  [this.$moment().subtract(1, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(1, "months")
                  ),
                  [this.$moment().subtract(0, "months").format("MMMM-YYYY")]: this.calcTempsPlein(
                    activeContrat.contrat_date_debut,
                    activeContrat.contrat_date_fin,
                    this.$moment().subtract(0, "months")
                  ),
                });
              }
            });
          }
        });
      }
      return rep;
    },
  },
};
</script>
<style lang="css">
.hover-danger {
  color: #007bff;
}
.hover-danger:hover {
  color: #dc3545;
}
.hover-danger.active {
  color: #dc3545;
}

@media (min-width: 768px) {
  .width-item {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .width-item {
    width: calc(100% / 2);
  }
}
@media (min-width: 1200px) {
  .width-item {
    width: calc(100% / 2);
  }
}
@media (min-width: 1500px) {
  .width-item {
    width: calc(100% / 3);
  }
}
</style>
